// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import styles from "assets/jss/material-kit-pro-react/components/parallaxStyle.jsx"
// nodejs library that concatenates classes
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import React from "react"
import { Background, Parallax } from "react-parallax"

const useStyles = makeStyles(styles)

export default function ReactParallax(props) {
  let windowScrollTop
  if (typeof window !== "undefined" && window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3
  } else {
    windowScrollTop = 0
  }
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  )
  React.useEffect(() => {
    if (typeof window !== "undefined" && window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform)
    }
    return function cleanup() {
      if (typeof window !== "undefined" && window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform)
      }
    }
  }, [transform])
  const resetTransform = () => {
    var windowScrollTop =
      typeof window !== "undefined" && window.pageYOffset / 3
    setTransform("translate3d(0," + windowScrollTop + "px,0)")
  }
  const {
    filter,
    className,
    children,
    style,
    imageStyle,
    imageTitle,
    imageData,
    objectFit,
    objectPosition,
    small,
  } = props
  const classes = useStyles()
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes[filter + "Color"]]: filter !== undefined,
    [classes.small]: small,
    [className]: className !== undefined,
  })
  return (
    <div id="parallax">
      <Parallax
        strength={100}
        className={parallaxClasses}
        style={{
          ...style,
          transform: transform,
        }}
      >
        <Background>
          <GatsbyImage
            className={classes.parallaxImageWrapper}
            objectFit={objectFit}
            objectPosition={objectPosition}
            image={imageData}
            alt={imageTitle}
            style={{ ...imageStyle }}
          />
        </Background>
        <div className={classes.parallaxChildrenWrapper}>{children}</div>
      </Parallax>
    </div>
  )
}

ReactParallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger",
  ]),
  children: PropTypes.node,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
  imageData: PropTypes.object,
  imageTitle: PropTypes.string,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  small: PropTypes.bool,
}
